<template>
	<figure id="background-direita">
		<img class="img-responsive" alt="Imagem" :src="imagem">
	</figure>
</template>

<script>
	import Imagem from "@/assets/images/fundo_branco.png"
	export default {
		name: 'LoginEsquerda',
		data() {
			return {
				imagem: Imagem,
			}
		},
	}
</script>

<style lang="scss">
	#background-direita{
		width: 100%;
		height: 100%;
		justify-content: center;
		align-items: center;
		display: flex;
		flex-wrap: wrap;
        img{
            height: 100%;
            margin-left: 0;
        }
	}
</style>